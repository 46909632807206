<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
    <md-card >
      <md-card-header>
        <md-card-header-text>
          <div v-if="newPartida" class="md-title">Nueva Partida</div>
            <div v-else class="md-title">Editando Partida</div>
        </md-card-header-text>
        <md-menu md-size="big" md-direction="bottom-end">
          <md-button class="md-icon-button"
            @click="go_to('/partidas')">
            <md-icon>keyboard_backspace</md-icon>
          </md-button>
        </md-menu>
      </md-card-header>
      <md-card-content>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100 md-xsmall-size-100">
          <md-field :class="getValidationClass('date_partida')" class="md-focused">
            <label for="date_partida" >Fecha partida</label>
            <md-input type="date" name="date_partida"
              id="date_partida" v-model="instance.date_partida" :disabled="sending" />
            <span class="md-error"
              v-if="!$v.instance.date_partida.required">
              La fecha de la partida es requerida</span>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-xsmall-size-100">
          <div class="md-layout-item">
            <md-field :class="getValidationClass('tipo_partida')">
              <label for="type_partida">Tipo partida</label>
            <md-select v-model="instance.tipo_partida"
              name="movie"
              id="movie"
              :disabled="sending"
            >
            <md-option :value="item.value"
              v-for="(item, key) in choosen_type_partida"
              :key="key">{{item.text}}</md-option>
            </md-select>
            <span class="md-error"
              v-if="!$v.instance.tipo_partida.required">
              El tipo de partida es requerido</span>
            </md-field>
          </div>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="numero_partida">No. partida</label>
            <md-input type="number" readonly
              id="numero_partia" name="numero_partida"
              v-model="instance.numero_partida" :disabled="sending" />
          </md-field>
        </div>

      </div>

      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100 md-xsmall-size-100">
          <div class="md-layout-item">
                <md-field>
                  <label for="descripcion_partida">Descripción partida</label>
                    <md-input name="descripcion_partida"
                      id="descripcion_partida"
                      v-model="instance.descripcion" :disabled="sending" />
                </md-field>
              </div>
        </div>
        <div class="moveRigth" >
          <md-button class="md-primary" v-if="!instance.id"
            @click="savePartida()">
            Detalle Partida
          </md-button>
        </div>
      </div>
      <div>
        <div v-if="instance.id">
          <basePartida :id_partida = "instance.id" :view = "view" />
        </div>

        <div class="md-layout-item md-small-size-100" v-if="instance.id" >
           <md-button class="md-primary margen"
              @click="end()" v-if="newPartida">
                Guardar Partida
            </md-button>
            <md-button class="md-primary margen"
              @click="modificarPartida()" v-else>
              Guardar Cambios
            </md-button>
        </div>
      </div>
      </md-card-content>
    </md-card>
    <br>
    <br>
    </div>

    <md-snackbar
      :md-active.sync="partida">La partida {{ document }} ha sido actualizada!</md-snackbar>
  </div>
</template>
<script>
/* eslint-disable */
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
} from 'vuelidate/lib/validators'

export default {
  name: 'Partida',
  created(){
    this.instance.id = this.$route.params.id
    if (this.instance.id){
      this.retrievePartida();
      this.newPartida = false;
      //this.instance.id = this.instance.id
    }
    this.get_data_storage('book_diary').then((r) => {
      this.book_diary = r
      if (this.book_diary === null){
        Swal.fire({
          title: "Aviso",
          text: "Debe de ingresar libro diario, para crear partidas",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push('/libro_diario');
          }
        });
      }else{
        this.$store.state.book_diary = this.book_diary.id
      }
    })


  },
  mixins: [validationMixin],
  components: {
    basePartida: () => import('../components/BasePartida.vue'),
  },
  data() {
    return {
      base: process.env.VUE_APP_BASE_URL,
      book_diary: null,
      instance: {
        id: null,
        date_partida: null,
        tipo_partida: null,
        descripcion: null,
        numero_partida: 0,
        id: null,
        book: null,
      },
      url_diarybook: 'diary_book_detail/',
      partidaSaved: false,
      sending: false,
      document: null,
      partidaSaved: false,
      partida: false,
      newPartida: true,
      hidden: false,
      view: true,
      choosen_type_partida: [
        {value:'DR', text: 'DIARIO'},
        {value:'CXC', text: 'CXC'},
        {value:'CXP', text: 'CXP'},
        {value:'BAN', text: 'BANCO'},
      ],
    };

  },
  validations: {
    instance: {
      date_partida: {
        required,
      },
      tipo_partida: {
        required,
      },
    },
  },
  methods: {
    go_to(url) {
      self.newPartida = true
      this.$router.push(url);
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    async get_data_storage(name) {
      return await JSON.parse(localStorage.getItem(name));
    },
    handlerError: (error) => {
      const result = error.response.data;
      const self = this;
      if (typeof (error.response.data) === 'object') {
        Object.entries(result).forEach(([key, value]) => { window.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
        return false;
      }
      self.$swal.fire('Error!', result, 'error');
      return false;
    },
    getValidationClass (fieldName) {
      const field = this.$v.instance[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm () {
      this.$v.$reset()
    },
    validatePurchase () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.savePartida()
      }
    },
    end(){
      const self = this
      self.document = `${self.instance.tipo_partida}-${self.instance.numero_partida}`
      self.partida = true
       setTimeout(() => self.go_to('/partidas'), 2000);
    },
    savePartida() {
      const self = this
      if (!this.instance.date_partida) {
        self.$swal.fire('Error!', 'Tiene que ingresar fecha de la partida.', 'error')
        return false
      }
      if (!this.instance.tipo_partida) {
        self.$swal.fire('Error!', 'Tiene que ingresar tipo de la partida.', 'error')
        return false
      }
      const payload = this.instance
      payload.book = this.$store.state.book_diary
      this.sending = true
      this.postRequestEncabezadoPartida(payload).then(function (data) {
        self.partidaSaved = true
        self.sending = false
        self.instance = data.data
      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    modificarPartida() {
      const self = this;
      if (parseFloat(this.diferencia) >= 0.01) {
        self.$swal.fire('Error!', 'Tiene diferencia en la partida.', 'error')
        return false
      }
      const payload = this.instance
      //payload.descripcion = this.instance.descripcion
      // payload.id = this.instance.id

      this.sending = true
      if (this.instance.id) {
        this.putRequestEncabezadoPartida(payload).then(function (data) {
          self.partida = true
          self.sending = false
        }).catch(function (error) {
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
            return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
      }
    },
    async retrievePartida () {
      const self = this
      const payload = {
        id: this.instance.id,
      }
      await this.retrieveRequest(this.url_diarybook, payload).then(function (data) {
        self.partida = false
        self.instance = data.data
      }).catch(function (error) {
        self.handlerError(error)
      })
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async postRequestEncabezadoPartida (payload) {
      const result = await this.$http.post(this.url_diarybook, payload)
      return result
    },
    async putRequest (payload) {
      const result = await this.$http.put(`${this.url}${payload.id}/`, payload)
      return result
    },
    async putRequestEncabezadoPartida (payload) {
      const result = await this.$http.put(`${this.url_diarybook}${payload.id}/`, payload)
      return result
    },
    async retrieveRequest (url_diarybook, _payload) {
      const result = await this.$http.get(url_diarybook + _payload.id + '/', { params: _payload })
      return result
    },

  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    },
  },
  computed: {
    settings_obj() {
      return this.$store.state.settings_obj;
    },
  },
};

</script>
<style lang="css" scoped>
 .success {
    font-weight: 500;
    background-color: #4aad09;
  }
  .warning {
    font-weight: 500;
    background-color: yellow;
  }
  .danger {
    font-weight: 500;
    background-color: red;
  }
  table {
    font-size: 2em;
  }
  .customWidthClass{
    width: 10%;
  }
  .moveRigth{
    display: block;
    float: right;
    margin-right: 20%;
    margin-top: 2%;
  }
  .moveLeft{
    display: block;
    float: left;
  }
  .size{
    width: 150px;
    margin-left: 20px;
  }
  .margen{
    margin-left: 450px;
    margin-bottom: 30px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #424242 inset;
  }
</style>
